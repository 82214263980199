import React, { useEffect } from 'react';
import BankDetails from '../../savings/BankDetails'
import { WizardModal } from '../../shared'
// import { printCurrency } from '../../../lib/utils'
// import {} from './ClearanceCertificatePayment.style'
import { useQuery } from 'react-apollo'
import gql from 'graphql-tag'
import { ActivityIndicator, Text } from '../../generic'
import MokuHoldingCard from '../../shared/MokuHoldingCard'
import QuickQuotePrice from '../../funeral/QuickQuotePrice/QuickQuotePrice'
import { printCurrency } from '../../../lib/utils'
import { navigate } from 'gatsby'
import { DebitOrderMandate } from '../../shared'
import { gotoFlowComponent } from '../../generic/ChatFlow'
import { banks } from '../../../constants'
import { isValidEmail, isValidCellphoneNumber, isRequired, isRequiredForThirdPartyAccount } from '../../../lib/validators'
import { MokuTitle } from '../../funeral/FuneralQuickQuote/FuneralQuickQuote'
import { FieldValue } from 'react-form-helper'
import AddBankAccount from '../../profile/AddBankAccount'
import firebase from 'gatsby-plugin-firebase'



// this page is not public facing so access has been removed for now
// const GET_CLEARANCE_CERTIFICATE_DATA = gql`
//   query($id: String, $accessCode: String) {
//     getClearanceCertificate(clearanceCertificateId: $id, accessCode: $accessCode) {
//       amount
//       accessCode
//       certificateNumber
//       client {
//         idNumber
//       }
//     }
//   }
// `

const GET_CLIENT_INFO = gql`{
  myClient {
    id
    bankAccounts {
      id
      bankName
      accountNo
    }
    clearanceCertificates {
      id
      amount
      certificateNumber
      clientId
      debitOrderId
    }
  }
}`

const GET_INSTALLMENT_PRICE = gql`
  query($id: String, $numInstallments: Int) {
    getClearanceInstallmentPrice(clearanceCertificateId: $id, numInstallments: $numInstallments) 
  }
`

const UPDATE_CLEARANCE_CERTIFICATE = gql`
  mutation UpdateClearanceCertificate($id: String!, $input: UpdateClearanceCertificateInput!) {
    updateClearanceCertificate(id: $id, input: $input) {
      debitOrderId
    }
  }
`

const OZOW_PAYMENT = gql`
query ($redirectUrl: String!, $associatedProducts: [ProductAmountInput!]!) {
    generateOzowPaymentUrl(redirectUrl: $redirectUrl, associatedProducts: $associatedProducts)
  }
`

const buildSteps = (clearanceCertificate, id, setOpen, refetch) => ([
  {
    name: "numInstallments",
    text: ({ amount, installmentAmount }) => <>
      <MokuHoldingCard>
        <FieldValue name="payment" renderField={({ input }) => <QuickQuotePrice title="Meerkat Clearance Certificate" price={`${printCurrency(input.value && input.value.numInstallments == 3 ? installmentAmount / 3 : amount)}`} color="white" />} />
      </MokuHoldingCard>
      <p />
      <p />
    </>,
    title2: "Choose a Payment Option",
    fieldNames: ["payment.numInstallments"],
    nextButtonsInColumn: true,
    nextSteps: ["howPay"]
  }, {
    beforeStep: ({ chatState, setChatState }) => {
      const selectedPayment = chatState.payment.numInstallments == 3 ? (chatState.installmentAmount / 3) : chatState.amount
      setChatState({ selectedPayment })
    },
    name: "howPay",
    text: ({ selectedPayment }) => <><MokuHoldingCard>
      <QuickQuotePrice title="Meerkat Clearance Certificate" price={`${printCurrency(selectedPayment)}`} color="white" />
    </MokuHoldingCard><p /></>,
    title2: "How would you like to pay?",
    nextButtons: ({ payment }) => {
      let buttons = []
      if (payment.numInstallments == 1) {
        buttons.push(
          { label: "Instant EFT", nextSteps: ["instantEFTLink"], buttonProps: { square: false } }, 
          { label: "Manual EFT", nextSteps: ["manualEFT"], buttonProps: { square: false } }, 
        )
      }
      buttons.push(
        /*{ label: "Once Off Debit Order", nextSteps: ["done"], buttonProps: { square: true } },
        { label: "Easy Pay", nextSteps: ["done"], buttonProps: { square: true } },*/
        { label: "DebiCheck", nextSteps: ["ownAccount"], buttonProps: { square: false } },
      )
      return buttons
    },
    nextButtonsInColumn: true,
    showBack: true,
  }, 
  {
    name: "instantEFTLink",
    title: "Redirect to payment partner",
    title2: (
      <>
        <p />
        <Text size={16}>
          After clicking the below button, we will redirect you to our secure
          payment partner to complete the transaction
        </Text>
        <p />
      </>
    ),
    handleNext: async ({ chatState, apolloClient }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_INSTANT_EFT_PAYMENT", {})

      apolloClient.mutate({
        mutation: UPDATE_CLEARANCE_CERTIFICATE, variables:
        {
          id,
          input: {
            paymentMethod: "INSTANT_EFT",
            numInstallments: chatState.payment.numInstallments,
          },
        }
      })

      const { data } = await apolloClient.mutate({
        mutation: OZOW_PAYMENT, variables:
        {
          redirectUrl: `${window.location.origin}/clearance-certificate/${id}`,
          associatedProducts: [{
            productId: id,
            productType: 'CLEARANCE_CERTIFICATE',
            amount: chatState.amount,
          }]
        }
      })
      const paymentUrl = data.generateOzowPaymentUrl
      window.location.href = paymentUrl;
    }
  },
  {    
    beforeStep: () => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_MANUAL_EFT_PAYMENT", {})
    },
    name: "manualEFT",
    title: ({ selectedPayment }) => <><MokuHoldingCard>
      <QuickQuotePrice title="Meerkat Clearance Certificate" disclaimer="" price={printCurrency(selectedPayment)} color="white" />
    </MokuHoldingCard><p /></>,
    title2: "Please make an EFT payment using the following details",
    component: () => <BankDetails reference={clearanceCertificate.certificateNumber} />,
    nextTitle: "Done",
    nextSteps: ["done"],
    showBack: true,
  },
  {
    name: "ownAccount",
    title2: "Will you be paying the account?",
    noNext: true,
    fieldNames: ["bankAccount.ownAccount"],
    nextOnValue: true,
    showBack: true,
    nextSteps: ({ bankAccount }) => bankAccount.ownAccount === "Y" ? ["bankAccountInfo"] : ["accountHolderInfo"]
  },
  {
    name: "accountHolderInfo",
    title: "Account Holder Details",
    fieldNames: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderEmail", "bankAccount.accountHolderIdNumber"],
    requiredFields: ["bankAccount.accountHolderName", "bankAccount.accountHolderContactNumber", "bankAccount.accountHolderIdNumber"],
    labels: true,
    nextSteps: ["paymentDay"],
    showBack: true,
  },
  {
    name: 'bankAccountInfo',
    title: 'Select your bank account',
    fieldNames: ['bankAccountId'],
    nextButtons: [
      {
        label: 'Add Bank Account',
        buttonProps: { inverted: true, outlined: false, disabled: false },
        nextSteps: ['addBankAccount'],
      },
      { label: 'Confirm', nextSteps: ['paymentDay'] },
    ],
    nextButtonsInColumn: true,
    showBack: true,
  },
  {
    name: 'addBankAccount',
    component: gotoFlowComponent(AddBankAccount, ({ gotoNext, changeFieldValue }) => ({
      startWithCleanState: false,
      clientId: clearanceCertificate.clientId,
      afterSubmit: (bankData) => {
        refetch().then(() => {
          changeFieldValue('bankAccountId', bankData.data.createBankAccount.id)
          gotoNext()
        })
      },
    })),
    noNext: true,
    nextSteps: ['bankAccountInfo'],
  },
  {
    name: "paymentDay",
    text: <MokuTitle title="Please select the payment date" />,
    fieldNames: ["payment.day"],
    requiredFields: ["payment.day"],
    labels: true,
    nextSteps: ["doSign"],
    showBack: true
  },
  {
    name: "doSign",
    beforeStep: async ({ apolloClient, chatState, setChatState }) => {
      await apolloClient.mutate({
        mutation: UPDATE_CLEARANCE_CERTIFICATE, variables:
        {
          id,
          input: {
            paymentMethod: "DEBI_CHECK",
            paymentDay: chatState.payment.day,
            numInstallments: chatState.payment.numInstallments,
            paymentBankAccountId: chatState.bankAccountId
          },
        }
      })
    },
    nextAfterDelay: 1000,
    noNext: true,
    nextSteps: ["doMandate"],
  },
  {
    beforeStep: async ({ setChatState }) => {
      firebase.analytics().logEvent("CLEARANCE_CERTIFICATE_DEBI_CHECK_PAYMENT", {})

      refetch().then((clientData) => {
        const clearanceCertificate =  clientData.data.myClient.clearanceCertificates.filter(cc => cc.id === id)[0]
        setChatState({debitOrderId: clearanceCertificate.debitOrderId })
      })
    },
    name: "doMandate",
    component: gotoFlowComponent(DebitOrderMandate, ({ chatState, ...args }) => { console.log(chatState, args); return { id: chatState.debitOrderId, startWithCleanState: false, noCloseButton: true } }),
    noNext: true,
    skipStep: ({ debitOrderId }) => !debitOrderId,
    nextSteps: ["done"]
  },
  {
    name: "done",
    beforeStep: () => {
      setOpen(false);
      navigate(`/clearance-certificate/${id}`)
    }
  }])

const fields = {
    payment: {
      day: {
        label: "When do you get paid?",
        type: 'radiogroup',
        options: [
          { label: "1st of the Month", value: 1 },
          { label: "15th of the Month", value: 15 },
          { label: "20th of the Month", value: 20 },
          { label: "25th of the Month", value: 25 },
          { label: "Last Day of the Month", value: 31 },
        ],
        includeOther: true,
        otherField: {
          type: 'select',
          options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
          label: "Select Payment Day"
        },
        keyboard: 'numeric',
        maxLength: 2,
        validate: [isRequired]
      },
      numInstallments: {
        type: "radiogroup",
        options: [],
        validate: [isRequired]
      }
    },
    bankAccount: {
      bankName: { type: 'select', options: banks.map(b => b.label), label: 'Bank', validate: [isRequired] },
      accountNo: { type: 'text', label: 'Account Number', validate: [isRequired] },
      ownAccount: { type: 'yesno', column: true, label: "Policy holder's account" },
      accountHolderName: { type: 'text', label: 'Premium Payer Name', validate: [isRequiredForThirdPartyAccount] },
      accountHolderContactNumber: { type: 'text', label: 'Contact Number', validate: [isRequiredForThirdPartyAccount, isValidCellphoneNumber] },
      accountHolderEmail: { type: 'text', label: 'Contact Email', validate: [isValidEmail] },
      accountHolderIdNumber: { type: 'text', label: 'Id Number', validate: [isRequiredForThirdPartyAccount] }
    },
    bankAccountId: {
        type: 'radiogroup',
        options: [],
        validate: (value) => (!value ? 'Select one' : undefined),
      }
}

function ClearanceCertificatePayment({ open, setOpen, id }) {
  const { data: clientData, loading: clientDataLoading, error: clientDataError, refetch } = useQuery(GET_CLIENT_INFO);
  const { data: installmentData, loading: installmentLoading, error: installmentError } = useQuery(GET_INSTALLMENT_PRICE, { variables: { id, numInstallments: 3 } });

  useEffect(() => {
    if (clientData && installmentData) {
      const clearanceCertificate = clientData.myClient.clearanceCertificates.filter(cc => cc.id === id)[0];
      
      const bankAccounts = clientData.myClient.bankAccounts;
     
      if (fields.bankAccountId) {
        fields.bankAccountId.options = bankAccounts.map((b) => ({
          value: b.id,
          label: (
            <>
              <Text bold>{b.bankName}</Text>
              <Text bold>Acc no. {b.accountNo}</Text>
            </>
          ),
        }));
      }
      if (fields.payment.numInstallments) {
        fields.payment.numInstallments.options = [{ value: 1, label: `Once Off Payment - ${printCurrency((clearanceCertificate && clearanceCertificate.amount) || 1150)}` }, { value: 3, label: `3 Monthly payments - ${printCurrency(installmentData.getClearanceInstallmentPrice / 3)} per month` }]
      }
    }

  }, [clientData, id, installmentData]);

  if (installmentLoading || clientDataLoading) {
    return <ActivityIndicator />
  }

  if (clientDataError || installmentError) {
    return <Text>Error loading data</Text>;
  }

  const clearanceCertificate = clientData.myClient.clearanceCertificates.filter(cc => cc.id === id)[0];

  if (!clearanceCertificate) {
    return <div />
  }

  return (
    <WizardModal
      name="clearanceCertificatePayment"
      open={open}
      setOpen={setOpen}
      steps={buildSteps(clearanceCertificate, id, setOpen, refetch)}
      initialValues={{ amount: clearanceCertificate.amount, installmentAmount: installmentData.getClearanceInstallmentPrice }}
      fields={fields}
    />
  );
}

export default ClearanceCertificatePayment
